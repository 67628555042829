/* Enhance overall background and spacing */
.c-section {
    padding: 40px;
    margin: 0 auto; /* Center the section horizontally */
    text-align: center;
    background-color: #f4f4f9; /* Lighter background */
    background-image: linear-gradient(to right top, #e6e9f0, #eff1f5, #f8f9fa, #ffffff, #ffffff); /* Subtle gradient */
}

/* Typography enhancements */
.c-title {
    font-family: 'Poppins', sans-serif;
    font-size: 40px;
    font-weight: bold;
    color: #333; /* Darker color for better readability */
    letter-spacing: 1px;
}

/* Form field enhancements */
input[type="text"], input[type="email"], textarea {
    width: 100;
    height: 50px;
    padding: 12px 20px;
    border: 2px solid #ccc; /* Subtle border */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 2px 5px rgba(0,0,0,0.1); /* Soft shadow for depth */
    transition: all 0.3s; /* Smooth transition for hover effects */
}

input[type="text"]:hover, input[type="email"]:hover, textarea:hover {
    border-color: #9D2553; /* Highlight color on hover */
}

/* Button styling enhancements */
button[type="submit"] {
    width: 180px;
    height: 50px;
    font-size: 18px;
    background-color: #9D2553; /* Consistent color theme */
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    transition: background-color 0.3s, box-shadow 0.3s;
}

button[type="submit"]:hover {
    background-color: #7a1f42; /* Darker shade on hover */
    box-shadow: 0 6px 12px rgba(0,0,0,0.15);
}

/* Responsive adjustments */
@media (max-width: 700px) {
    .c-title { font-size: 32px; }
    .c-description { margin: 48px 20px 0; font-size: 18px; }
    .contact-form { padding: 20px; }
}
