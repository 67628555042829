.p-section {
    padding: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
    background-color: white;
}
/* Image */
.p-image-content {
    width: 50%;
    max-width: 100%;
    text-align: center;
}
.p-image1 {
    width: 80%;
    height: auto;
}
/* Text */
.p-text-content {
    width: 50%;
}
.p-title {
    font-family: 'Poppins', sans-serif;
    font-size: 40px;
    font-weight: bold;
    letter-spacing: .7px;
}
.p-title > span {
    position: relative;
}
.p-title span::before {
    content: '';
    position: absolute;
    width: 75%;
    height: 4px;
    background-color: #9D2553;
    border-radius: 8px;
    left: 0;
    bottom: -12px;
}
.p-description {
    margin: 32px 0;
    color: #4d4b4b;
    font-family: 'Rubik', sans-serif;
    font-size: 18px;
    letter-spacing: .8px;
    line-height: 1.8rem;
}





@media screen and (max-width: 1000px) {
    .p-section {
        display: block;
    }
    .p-image-content {
        width: 100%;
        max-width: 100%;
        margin: 0 0 32px 0;
    }
    .p-text-content {
        width: 100%;
    }
    .p-image1 {
        width: 80%;
        height: 80%;
    }
}
